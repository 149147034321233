<template>
  <div lang="fr">
    <section class="premium-offers">
      <section class="hero">
        <Card
          isReverse
          heading="Passez à l’électrique et profitez d’une recharge gratuite"
          headlineLevel="h1"
          :maxWidth="1200"
        >
          <template #image>
            <picture
              ><source media="(min-width: 1200px)" srcset="@/assets/images/PremiumOffers/desert-charger.jpg" />
              <img class="image" src="@/assets/images/PremiumOffers/desert-charger.jpg" alt="" /> </picture
          ></template>
          <p>
            Profitez d’une recharge gratuite à l’achat ou à la location d’un véhicule électrique auprès d’une marque
            participante.*
          </p>
        </Card>
      </section>
      <section class="partners">
        <h2>Optez pour la marque ultime</h2>
        <p>
          Lorsque vous achetez un véhicule électrique par l’intermédiaire d’un fabricant participant, obtenez
          <strong>une recharge ultrarapide gratuite ou encore plus d’avantages offerts aux membres* </strong>sans frais
          supplémentaires.
        </p>
        <div class="partners__logo">
          <router-link :to="{ name: 'e-tron-fr' }"
            ><img src="@/assets/images/PremiumOffers/audi-logo.webp" alt="Go to Audi page"
          /></router-link>
          <router-link :to="{ name: 'bmw-i7-fr' }"
            ><img src="@/assets/images/PremiumOffers/bmw-logo.webp" alt="Go to BMW page"
          /></router-link>
          <!-- <a href="https://www.lucidmotors.com/fr-ca/" rel="noopener" target="_blank"
            ><img src="@/assets/images/PremiumOffers/lucid-motors-logo.webp" alt="Logo Lucid"
          /></a> -->
          <router-link class="link" :to="{ name: 'taycan-fr' }"
            ><img src="@/assets/images/PremiumOffers/porsche-logo.webp" alt="Go to Porsche page"
          /></router-link>
          <router-link :to="{ name: 'vw-id4-fr' }"
            ><img src="@/assets/images/PremiumOffers/volkswagen-logo.webp" alt="Go to Volkswagen page"
          /></router-link>
        </div>
        <p><small>Les marques sont classées par ordre alphabétique.</small></p>
      </section>
      <section class="go-electric bg-gray">
        <h2 class="partners">En savoir plus sur les véhicules électriques</h2>
        <div class="l-three-col">
          <Card
            heading="Familiarisez-vous avec les bases de la recharge des VE"
            headlineLevel="h3"
            isSmall
            :isReverse="isPhone"
            headlineDisplayClass="headline2"
          >
            <template #image>
              <img loading="lazy" src="@/assets/images/PremiumOffers/bulb.svg?external" alt="" />
            </template>
            <p>
              Apprenez
              <router-link
                :to="{ name: 'what-to-expect-fr' }"
                class="link"
                aria-label="à quoi s’attendre la borne de recharge"
                >à quoi s’attendre</router-link
              >
              à la borne de recharge et
              <router-link :to="{ name: 'charging-your-ev-fr' }" class="link">comment recharger</router-link>
            </p>
          </Card>
          <Card
            heading="Trouvez des bornes de recharge ultrarapide près de chez vous"
            headlineLevel="h3"
            isSmall
            :isReverse="isPhone"
            headlineDisplayClass="headline2"
          >
            <template #image>
              <img loading="lazy" src="@/assets/images/PremiumOffers/ea-bolt.svg?external" alt="" />
            </template>
            <p>
              <router-link :to="{ name: 'locate-charger-fr' }" class="link">Trouvez une borne de recharge </router-link>
              sur le site Web et affichez la disponibilité en temps réel
              <router-link
                :to="{ name: 'mobile-app-fr' }"
                class="link"
                aria-label="La tarification en temps réel peut être obtenue dans l’application ou à la borne de recharge."
              >
                dans l’application</router-link
              >
            </p>
          </Card>
          <Card
            heading="Inscrivez-vous à votre offre Premium avec l’appli"
            headlineLevel="h3"
            isSmall
            :isReverse="isPhone"
            headlineDisplayClass="headline2"
          >
            <template #reference>
              <reference-link :num="1">
                Les frais de messagerie et de données ainsi que les autres frais prélevés par votre fournisseur de
                services cellulaires s’appliqueront à votre utilisation de l’application Electrify Canada.
              </reference-link>
            </template>

            <template #image>
              <img loading="lazy" src="@/assets/images/PremiumOffers/electric-bolt.svg?external" alt="" />
            </template>
            <p>
              Apprenez comment
              <router-link :to="{ name: 'mobile-app-fr' }" class="link">créer un compte</router-link> et utiliser votre
              recharge gratuite*
            </p>
          </Card>
        </div>
      </section>
      <section class="partners app">
        <h2>Utilisez l’application Electrify Canada pour vous inscrire et économiser</h2>
        <p>
          Si vous possédez ou louez un véhicule avec une offre Premium de l’une de ces marques, utilisez l’application
          Electrify Canada pour vous inscrire.
          <reference-link :num="1">
            Les frais de messagerie et de données ainsi que les autres frais prélevés par votre fournisseur de services
            cellulaires s’appliqueront à votre utilisation de l’application Electrify Canada.
          </reference-link>
        </p>
        <div class="app__logo">
          <div>
            <img src="@/assets/images/PremiumOffers/audi-logo.webp" :alt="isMobile ? 'Audi' : ''" />
            <strong v-if="!isMobile">Audi</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/bmw-logo.webp" :alt="isMobile ? 'BMW' : ''" />
            <strong v-if="!isMobile">BMW</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/volkswagen-logo.webp" :alt="isMobile ? 'Volkswagen' : ''" />
            <strong v-if="!isMobile">Volkswagen</strong>
          </div>
        </div>
      </section>
      <section class="redeem-charging">
        <Card
          headlineLevel="h2"
          heading="Utilisez un code d’inscription pour utiliser votre recharge gratuite"
          headlineDisplayClass="headline2"
          isReverse
          :maxWidth="1100"
        >
          <template #image>
            <picture>
              <img loading="lazy" class="image" src="@/assets/images/PremiumOffers/mobile-fr.png" alt="" />
            </picture>
          </template>
          <p>
            <span class="redeem-charging__number"><strong>1</strong></span> Téléchargez l’application Electrify
            Canada<reference-link :num="1">
              Les frais de messagerie et de données ainsi que les autres frais prélevés par votre fournisseur de
              services cellulaires s’appliqueront à votre utilisation de l’application Electrify Canada.
            </reference-link>
            et créez votre compte
          </p>
          <p>
            <span class="redeem-charging__number"><strong>2</strong></span> Sélectionnez
            <strong>Offre Premium</strong> à l’onglet Compte
          </p>
          <p>
            <span class="redeem-charging__number"><strong>3</strong></span> Choisissez<strong>
              J’ai un code d’inscription</strong
            >
          </p>
          <p>
            <span class="redeem-charging__number"><strong>4</strong></span> Entrez votre code d’inscription et
            sélectionnez votre forfait
          </p>
          <p class="flex-display">
            <span class="redeem-charging__number"><strong>5</strong></span>
            Acceptez la divulgation du forfait, puis ajoutez un mode de paiement secondaire pour tous les frais qui ne
            sont pas couverts par la recharge gratuite**
          </p>
          <p class="redeem-charging__mr">
            <strong>Remarque: </strong>Certains constructeurs automobiles ont leur propre application mobile pour
            s’inscrire à la recharge gratuite. La section suivante fournit de plus amples renseignements.
          </p>
          <div v-if="!isMobile" class="inline">
            <a rel="noopener" target="_blank" href="https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=fr">
              <img
                loading="lazy"
                :src="require('@/assets/images/Global/app-store-logo-fr.png')"
                :alt="$t('downloadInTheAppStore')"
              />
            </a>
            <a
              rel="noopener"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=fr_CA"
            >
              <img
                loading="lazy"
                :src="require('@/assets/images/Global/google-play-badge-fr.png')"
                :alt="$t('getItOnGooglePlay')"
              />
            </a>
          </div>
        </Card>
      </section>
      <section class="ea-benefit bg-gray">
        <h2>Utilisez l’application de votre véhicule pour vous inscrire</h2>
        <p>
          Si vous possédez ou louez un véhicule avec une recharge gratuite de l’une de ces marques, utilisez leur
          application pour vous inscrire.
        </p>
        <div class="ea-benefit__logo">
          <!-- <div>
            <img src="@/assets/images/PremiumOffers/lucid.webp" alt="Logo Lucid" />
            <strong v-if="!isMobile">Lucid</strong>
            <arrow class="arrow"></arrow>
          </div> -->
          <!-- <router-link :to="{ name: 'taycan-fr' }"> -->
          <div>
            <img src="@/assets/images/PremiumOffers/porsche.webp" :alt="isMobile ? 'Porsche' : ''" />
            <strong v-if="!isMobile">Porsche</strong>
            <!-- <arrow class="arrow"></arrow> -->
          </div>
          <!-- </router-link> -->
        </div>
        <!-- <p><small>Les marques sont classées par ordre alphabétique.</small></p> -->
      </section>
      <section class="pass-member">
        <Card
          headlineLevel="h2"
          heading="Devenez membre Passe+"
          :isReverse="true"
          headlineDisplayClass="headline3"
          class="no-bottom-padding"
          :maxWidth="1100"
        >
          <template #image>
            <picture>
              <source media="(min-width:750px)" srcset="@/assets/images/PremiumOffers/station.webp" />
              <source srcset="@/assets/images/PremiumOffers/station-mobile.webp" />
              <img loading="lazy" class="image" src="@/assets/images/PremiumOffers/station.webp" alt="" />
            </picture>
          </template>
          <p>
            Profitez de coûts de recharge réduits avec le réseau à l’avant-garde de l’offre de bornes ultrarapides (150
            kW) et hyper rapides (350 kW) au Canada.
          </p>
          <router-link class="button-link" :to="{ name: 'mobile-app-fr' }"
            >APPRENEZ-EN PLUS SUR LES CHOIX D’ADHÉSION<arrow class="arrow"></arrow
          ></router-link>
        </Card>
      </section>
      <section class="partners disclaimer">
        <p>
          <small>
            *Les détails du programme varient en fonction du modèle du véhicule, du fabricant et de la date dֹ’achat.
            Consultez les modalités de la promotion de votre véhicule pour connaître tous les renseignements et
            restrictions, accessibles à
            <router-link class="link" :to="{ name: 'e-tron-fr' }">Audi</router-link>,
            <router-link class="link" :to="{ name: 'bmw-i7-fr' }">BMW</router-link>,
            <!-- <a href="https://www.lucidmotors.com/fr-ca/" rel="noopener" target="_blank" class="link">Lucid</a>, -->
            <router-link class="link" :to="{ name: 'taycan-fr' }">Porsche</router-link>, et
            <router-link class="link" :to="{ name: 'vw-id4-fr' }">Volkswagen</router-link>.
          </small>
        </p>
        <p>
          <small>
            **Pour que vous puissiez recharger votre véhicule en tout temps, le solde de votre compte Electrify Canada
            est automatiquement réapprovisionné lorsqu’il descend sous le seuil de 5 $. Le montant de la recharge
            automatique que vous aurez choisi dans l’application vous sera facturé la première fois que vous rechargerez
            votre véhicule à une borne Electrify Canada. Le montant facturé sera conservé dans votre compte pour couvrir
            les coûts non couverts par ce forfait, comme les frais d’inactivité et les taxes applicables. Les
            utilisateurs qui ne débranchent pas et ne déplacent pas leur véhicule dans les dix minutes qui suivent la
            fin de leur séance de recharge se voient facturer des frais d’inactivité de 0,40 $ par minute. Ces frais
            visent à inciter les conducteurs à déplacer leur voiture rapidement afin que d’autres puissent utiliser la
            borne de recharge. Vous pouvez être informé de la fin de votre séance de recharge par un message texte (les
            frais de messagerie, de données et autres tarifs de votre fournisseur de services mobiles pourraient
            s’appliquer) ou vous pouvez consulter l’écran de la borne de recharge.</small
          >
        </p>
      </section>
    </section>
  </div>
</template>

<script>
import lang from '@/lang';
import Card from '@/components/Card/Card.vue';
import ReferenceLink from '@/components/ReferenceLink.vue';

import arrow from '@/assets/images/Global/arrow.svg';

export default {
  components: { arrow, Card, ReferenceLink },
  metaInfo: {
    title: 'Electrify Canada | Offres Premium',
    meta: [
      {
        name: 'description',
        content:
          'Profitez d’une recharge gratuite avec Electrify Canada à l’achat ou à la location d’un véhicule électrique auprès d’une marque participante.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/fr/offres-premium/' }],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>
